import { AvailableCountriesCapital, LocalId } from 'globalTypes'
import { Dispatch, ReactElement, SetStateAction } from 'react'
import { MessageFormatElement } from 'react-intl'
import { Props } from 'react-intl/src/components/message'
import { NavigationItemTitle } from '../components/AppShell/types'
import { LegacyRights } from 'services/api/endpoints/risikaAPI/accountStatus'
export type AuthType = 'staff' | 'admin' | 'user' | 'developer'

export type NavigationItem = {
  auth: AuthType[]
  icon: ReactElement
  id: string
  title: NavigationItemTitle
  permissions: keyof LegacyRights | 'observational_lists'
  type: string
  url: string
  action?: () => void
  dataCy?: string
  externalLink?: boolean
  subUrls?: string[]
}

export type Navigation = {
  children?: NavigationItem[]
  icon: string
  id: string
  title: string
  type: string
}[]

export type ReactSetState<T> = Dispatch<SetStateAction<T>>

export type RiskAssessmentCodeT = 'LOW' | 'MEDIUM' | 'HIGH'

export type ColorLevelT = 'success' | 'warning' | 'error'

export type LanguageTypes = 'da_DK' | 'en_UK' | 'nb_NO'

export type SupportedCountry = 'dk' | 'se' | 'no' | 'fi'

export type AvailableList = 'DK' | 'SE' | 'NO' | 'FI'

export const FraudIndicatorTabs = ['DK', 'SE', 'NO', 'FI'] as const

export type HTMLElementEvent<T extends HTMLElement> = Event & {
  target: T
}

export type Role = 'admin' | 'user'
export type SubscriptionPlan =
  | 'FREEMIUM'
  | 'BASIC'
  | 'STANDARD'
  | 'PRO'
  | 'ENTERPRISE'
  | 'TRIAL'

export type GazzeteReportType = {
  type: string // "DECREE" || "DECREE CANCELLED"
  source: string // "STATSTIDENDE"
  date: string // "2021-05-14"
  content: {
    link: string
    text: string
    case_id: string
    court_date: string
    event_date: string
    decree_date: string
    corrected_by?: string
    correction_of?: string
    court_case_id: string
  }
  messageTime: string // "2021-05-14T02:01:13.424028"
}

export type Address = {
  city?: string
  coname?: string | null
  coordinates?: {
    lat: number
    lng: number
  }
  country?: string
  local_region?: string
  municipality?: string
  number?: string
  postdistrict?: string
  street?: string
  zipcode?: number | string
}

type Trend = {
  current: number
  previous: number
  trend: 'UP' | 'DOWN'
}

export type FinancialTrends = {
  assets: Trend
  current_assets: Trend
  equity: Trend
  profit_loss: Trend
  report_period: Trend
}

type Ceo = {
  name: string
  personal_id: number
  valid_from: string
}

type Entity =
  | 'COMPANY'
  | 'LEGAL_OWNER'
  | 'BENEFICIAL_OWNER'
  | 'LEGAL_OWNER_AND_BENEFICIAL_OWNER'

// Consider moving this to the global types
export type CompanyHierarchy = {
  assets: number
  children?: Array<CompanyHierarchy>
  has_children: number
  currency: string
  current_assets: number
  equity: number
  financial_trend: FinancialTrends
  functions: {
    'CHIEF EXECUTIVE OFFICER'?: Ceo
  }
  has_parents?: number
  level: number
  local_id: LocalId
  local_organization_id: LocalId
  name: string
  personal_id: number
  profit_loss: number
  score: RisikaScoreType
  shares?: number
  shares_interval: string
  parents?: Array<CompanyHierarchy>
  type: Entity
}

export type ReactState<T extends {} | null> = React.Dispatch<React.SetStateAction<T>>

export type Remark = {
  creditor: string
  type: string
  case_id: string
  provider: string
  amount: number
  registered: string
  status: string
  settlement_type: 'Unsettled' | 'Partially Paid'
}

export type PaymentRemarksData = {
  total_unsettled_amount: number
  total_unsettled: number
  avg_days_unsettled: number
  percentage_partially_paid: number
  unique_creditors: number
  remarks: Remark[]
}

export type PoliticallyExposedPersons = {
  date_added_to_pep?: string
  description?: string
  description_code?: string
  duties: {
    function?: string
    duties: any
  }[]
}

export type Function = {
  function: string
  title: string
  valid_from: string
  valid_to: string
  shares?: number
  shares_interval?: number
}

export type Owner = {
  name: string
  type: string
  country: string
  functions: Array<Function>
  label: string
  shares: string | number | undefined
  personal_id: number | string
  inactiveFunctions?: Function[]
  special_ownership: {
    description: any
    title?: string
    valid_from?: string
    valid_to?: string
  }[]
  politically_exposed_persons?: PoliticallyExposedPersons
  active_company_relations: []
}

// Can be improved if extend general Error TODO
export type RisikaApiError = {
  response: {
    data: {
      error: string
    }
    status: number
    statusText: string
  }
}

export type WebApiError = {
  response: {
    data: {
      error: {
        name: string
        message: string
      }
    }
  }
}

export type CompanyType = {
  code: string
  title: string
}

export type CompanyTypesDK =
  | 'ABA'
  | 'AMT'
  | 'ANS'
  | 'APS'
  | 'A/S'
  | 'D\u00d8D'
  | 'EFO'
  | 'ENK'
  | 'E\u00d8F'
  | 'EUO'
  | 'FAP'
  | 'FAS'
  | 'FBA'
  | 'FFO'
  | 'FIV'
  | 'FKI'
  | 'FMA'
  | 'FON'
  | 'FOR'
  | 'GUS'
  | 'I/S'
  | 'IVS'
  | 'KAS'
  | 'KOM'
  | 'K/S'
  | 'MSS'
  | '\u00d8VR'
  | 'PAR'
  | 'PMV'
  | 'REG'
  | 'SCE'
  | 'SMA'
  | 'SOV'
  | 'STA'
  | 'UDL'
  | 'UOP'
export type RiskAssessmentLevelsType = 'HIGH' | 'MEDIUM' | 'LOW'

export type Policy = {
  id: number
  country: AvailableCountriesCapital
  description: string
  enabled: boolean
  created: Date
  updated: Date
  name: string
  owner_id: number
}
export type Option<T> = { label: T; value: T; node_text?: string | number }

export type FormattedMessage = React.NamedExoticComponent<
  Props<Record<string, React.ReactNode>>
>

export type Messages = Record<string, string> | Record<string, MessageFormatElement[]>

export type RisikaScoreType = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10

export type ErrorResponse = {
  response: {
    data: {
      error: string
    }
  }
}
