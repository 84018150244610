import { Box, Modal, Typography } from '@mui/material'
import { ButtonPrimary, ButtonSecondary } from 'components-new'
import {
  buttonPrimary,
  contentBox,
  modalContainer,
  title,
} from './ConfirmNavigationModal.styles'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'

type ConfirmNavigationModalProps = {
  isOpen: boolean
  onDiscardChanges: () => void
  onCancel: () => void
}

export const ConfirmNavigationModal = (props: ConfirmNavigationModalProps) => {
  const { isOpen, onDiscardChanges, onCancel } = props

  return (
    <Modal open={isOpen} data-cy="confirm-navigation-modal">
      <Box sx={modalContainer}>
        <Box sx={contentBox}>
          <Typography sx={title} variant="h4">
            <FormattedMessage id={intl.creditPolicyNew('unsaved-changes')} />
          </Typography>

          <ButtonPrimary sx={buttonPrimary} onClick={onDiscardChanges}>
            <FormattedMessage id={intl.creditPolicyNew('discard-changes')} />
          </ButtonPrimary>
          <ButtonSecondary sx={buttonPrimary} onClick={onCancel}>
            <FormattedMessage id={intl.creditPolicyNew('cancel')} />
          </ButtonSecondary>
        </Box>
      </Box>
    </Modal>
  )
}
