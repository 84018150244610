import { List, Skeleton, Stack } from '@mui/material'
import { useSelector } from 'react-redux'
import { isRoleAllowed } from 'services/user'
import { navigation as navService } from 'services/navigation'
import NavigationItemAction from './NavigationItemAction'
import NavigationItem from './NavigationItem'
import { AppDrawerProps } from './types'
import { useAccountStatus } from 'services/queries'
import { ReduxRootState } from '../../store_deprecated/types'
import {
  ArrayLegacyRight,
  LegacyRights,
  GlobalRights,
} from '../../services/api/endpoints/risikaAPI/accountStatus'

function AppDrawer({ navigation, roles }: AppDrawerProps) {
  const { history } = useSelector((state: ReduxRootState) => state.risika)
  const accountStatusQuery = useAccountStatus()
  const { data: statusData, isLoading: isStatusLoading } = accountStatusQuery
  const rights = statusData?.rights
  const legacy_rights = statusData?.legacy_rights
  const relationId = useSelector(
    (state: ReduxRootState) => state.risika.settings.persist_relation_overview_id
  )
  const [mainNavigation, supportNavigation] = navigation

  function allowedNavigation({ auth }: { auth: string[] }) {
    return isRoleAllowed(auth, roles)
  }

  const creditCheckHistory = history
    .filter((x) => x.location === 'creditcheck')
    ?.map((x) => ({
      country: x?.country,
      id: x?.historyKey,
      hash: x?.hash,
    }))

  const checkIfItemShouldBeDisabled = (title: string) => {
    switch (title) {
      case 'credit-check':
        return !creditCheckHistory?.[creditCheckHistory?.length - 1] // REACT-QUERY MIGRATION, FIX THIS!
      case 'relation-overview':
        return !relationId
      default:
        return false
    }
  }

  const handleUrl = (url: string, title: string) => {
    switch (title) {
      case 'credit-check':
        return navService.creditCheck(
          creditCheckHistory?.[creditCheckHistory?.length - 1]
        )
      default:
        return url
    }
  }

  const mainNavigationItemsFilteredByPermissions = mainNavigation?.children?.filter(
    (x) => {
      const hasLegacyRights =
        legacy_rights?.[x.permissions as keyof ArrayLegacyRight]?.length !== 0
      const hasLegacyRightsNotFalse =
        legacy_rights?.[x.permissions as keyof LegacyRights] !== false
      const hasGlobalRightsNotFalse =
        rights?.global?.[x.permissions as keyof GlobalRights] !== false
      return hasLegacyRights && hasLegacyRightsNotFalse && hasGlobalRightsNotFalse
    }
  )

  const mainNavigationItemsFilteredByAllowed =
    mainNavigationItemsFilteredByPermissions?.filter(allowedNavigation)

  const mainNavigationItems = mainNavigationItemsFilteredByAllowed?.map(
    ({ title, url, icon, dataCy, subUrls }) => {
      return isStatusLoading ? (
        <Stack direction="column" alignItems="center" key={title}>
          <Skeleton variant="circular" width={'70%'} height={65} />
          <Skeleton variant="text" />
        </Stack>
      ) : (
        <NavigationItem
          title={title}
          // This handles credit check and adds the now required params
          // Fix that once we make the hook for navigation (useNavigation)
          url={handleUrl(url, title)}
          icon={icon}
          key={title}
          disabled={checkIfItemShouldBeDisabled(title)}
          data-cy={dataCy}
          subUrls={subUrls}
          // onClick={() => handleTabSelection(title)}
        />
      )
    }
  )

  return (
    <Stack
      style={{ height: '100%' }}
      justifyContent="space-between"
      direction="column"
      spacing={4}
    >
      <List>{mainNavigationItems}</List>
      <List>
        {supportNavigation?.children?.length &&
          supportNavigation.children
            .filter((x) => x.url)
            .map(({ title, url, icon, externalLink, subUrls }) =>
              isStatusLoading ? (
                <Stack data-cy={title} key={title}>
                  <Skeleton variant="circular" width={210} height={118} />
                  <Skeleton variant="text" width={210} height={118} />
                </Stack>
              ) : (
                <NavigationItem
                  title={title}
                  url={url}
                  icon={icon}
                  key={title}
                  externalLink={externalLink}
                  subUrls={subUrls}
                />
              )
            )}
        {supportNavigation?.children?.length &&
          supportNavigation.children
            .filter((x) => x.action)
            .map(({ title, action, icon }) => (
              <NavigationItemAction
                title={title}
                action={action}
                icon={icon}
                key={title}
              />
            ))}
      </List>
    </Stack>
  )
}

export default AppDrawer
