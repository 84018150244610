import {
  LOGIN_USER_SUCCESS,
  VERIFY_USER_SUCCESS,
  ACTIVATE_USER_SUCCESS,
  LOGOUT_USER,
  UPDATE_USER_CURRENCY_SUCCESS,
  UPDATE_USER_SUCCESS,
  UPDATE_COMPANY_DOMAIN_AND_PHONE_SUCCESS,
  UPDATE_MODEL_SUCCESS,
  UPDATE_COMPANY_INFORMATION_SUCCESS,
  GET_USER_SUCCESS,
  FORCE_USER_PASSWORD_CHANGE,
  SET_USER_SETTINGS,
  SET_DASHBOARD_LANG,
} from './constants'
import { validateFutureState } from './validators'

/*********
 * Types *
 *********/

// type UpdateUser = {
//   firstName: string,
//   lastName: string,
//   phoneNumber: string,
// }

// type Settings = {
//   monitorTableFields: Array<string>,
// }

// type AuthReducerState = {
//   login: {
//     authenticated: boolean,
//     firstTimeVisit: boolean,
//     verifyToken: ?string,
//   },
//   user: {
//     data: {
//       name: {
//         first: string,
//         last: string,
//       },
//       email: string,
//       id: ?number,
//       phone: ?string,
//       avatar: string,
//       createdAt: ?string,
//     },
//     roles: ?(string[]),
//     local: {
//       currency: string,
//       lang: string,
//     },
//   },
//   company: Company,
//   settings: Settings,
// }

/*****************
 * Initial state *
 *****************/

export const initialStateAuth = {
  login: {
    authenticated: false,
    firstTimeVisit: false,
    shouldChangePassword: false,
    verifyToken: null,
  },
  user: {
    data: {
      name: {
        first: '',
        last: '',
      },
      email: '',
      id: null,
      phone: '',
      avatar: 'risika-logo',
      createdAt: null,
    },
    roles: null,
    local: {
      currency: 'DKK',
      lang: '',
    },
  },
  company: {},
  settings: {
    // monitorTableFields: [],
    // creditCheckFields: [],
    // companyInfoFields: [],
  },
}

/************
 * Reducers *
 ************/

const handleLoginUserSuccess = (state, action) => {
  const { user, settings } = action.payload
  return {
    ...state,
    login: {
      ...state.login,
      authenticated: true,
      firstTimeVisit: !user.is_verified,
      shouldChangePassword: user.should_change_password ?? false,
    },
    user: {
      ...state.user,
      data: {
        ...state.user.data,
        lastLogin: user.last_login,
        id: user.id,
        email: user.email,
        name: user.name,
        phone: user.phone_number,
        createdAt: user.created_at,
      },
      roles: [user.role],
      local: {
        ...state.user.local,
        lang: settings.language,
      },
    },
    settings: {
      // Add the new settings when you need to
      monitorTableFields: settings.monitor_table?.map((x) => x.key),
      creditCheckFields: settings.credit_check?.map((x) => x.key),
      fraudIndicators: settings.fraud_indicators?.map((x) => x.key),
      companyInfoFields: settings.company_info?.map((x) => x.key),
      selectedCurrency: {
        currency: settings.selected_currency.currency,
        countryCode: settings.selected_currency.country,
      },
      selectedCountry: settings.selected_country,
      // ...settings,
    },
  }
}

const handleVerifyUserSuccess = (state, action) => {
  const { user, verifyToken } = action.payload
  return {
    ...state,
    login: {
      ...state.login,
      authenticated: true,
      firstTimeVisit: true,
      verifyToken,
    },
    user: {
      ...state.user,
      data: {
        ...state.user.data,
        id: user.id,
        email: user.email,
        name: user.name,
        phone: user.phone_number,
      },
      roles: [user.role],
      local: {
        ...state.user.local,
        lang: user.language,
      },
    },
  }
}

const handleForceUserPasswordChange = (state) => {
  return {
    ...state,
    login: {
      ...state.login,
      shouldChangePassword: true,
    },
  }
}

const handleActivateUserSuccess = (state, action) => {
  return {
    ...state,
    login: {
      ...state.login,
      firstTimeVisit: false,
      shouldChangePassword: false,
    },
  }
}
const handleUpdateUserCurrencySuccess = (state, action) => {
  const { currency } = action.payload

  return {
    ...state,
    user: {
      ...state.user,
      local: {
        ...state.user.local,
        currency: currency,
      },
    },
  }
}

const handleUpdateUserSuccess = (state, action) => {
  const { name, phoneNumber } = action.payload
  return {
    ...state,
    user: {
      ...state.user,
      data: {
        ...state.user.data,
        name,
        phone: phoneNumber,
      },
    },
  }
}

const handleUpdateCompanyDomainAndPhoneSuccess = (state, action) => {
  const { domain, phoneNumber, model, modelObject } = action.payload

  return {
    ...state,
    company: {
      ...state.company,
      domain,
      phoneNumber,
      model,
      modelObject,
    },
  }
}

const handleUpdateModelSuccess = (state, action) => {
  const { modelObject } = action.payload

  return {
    ...state,
    company: {
      ...state.company,
      modelObject,
    },
  }
}

const handleUpdateCompanyInformationSuccess = (state, action) => {
  const company = action.payload

  return {
    ...state,
    company: {
      ...state.company,
      ...company,
    },
  }
}

const handleGetUserSuccess = (state, action) => {
  const { user } = action.payload

  return {
    ...state,
    user: {
      ...state.user,
      data: {
        ...state.user.data,
        id: user.id,
        email: user.email,
        name: {
          first: user.first_name ?? '',
          last: user.last_name ?? '',
        },
        phone: user.phone_number,
        createdAt: user.created_at,
      },
      roles: [user.role],
      local: {
        ...state.user.local,
        lang: user.language,
      },
    },
  }
}

const handleSetUserSettingsSuccess = (state, action) => {
  const { payload } = action
  const { field, newValues } = payload
  const futureState = {
    ...state.settings,
    [field]: newValues,
  }
  validateFutureState(field, futureState, state.settings)
  return {
    ...state,
    settings: {
      ...state.settings,
      [field]: newValues,
    },
  }
}

const handleSetDashboardLang = (state, action) => {
  return {
    ...state,
    user: {
      ...state.user,
      local: {
        ...state.user.local,
        lang: action.payload,
      },
    },
  }
}
/***********
 * Helpers *
 ***********/
/****************
 * Auth Reducer *
 ****************/

export const auth = (state = initialStateAuth, action = { type: '' }) => {
  switch (action.type) {
    case LOGIN_USER_SUCCESS:
      return handleLoginUserSuccess(state, action)
    case VERIFY_USER_SUCCESS:
      return handleVerifyUserSuccess(state, action)
    case ACTIVATE_USER_SUCCESS:
      return handleActivateUserSuccess(state, action)
    case FORCE_USER_PASSWORD_CHANGE:
      return handleForceUserPasswordChange(state, action)
    case UPDATE_USER_CURRENCY_SUCCESS:
      return handleUpdateUserCurrencySuccess(state, action)
    case UPDATE_USER_SUCCESS:
      return handleUpdateUserSuccess(state, action)
    case UPDATE_COMPANY_DOMAIN_AND_PHONE_SUCCESS:
      return handleUpdateCompanyDomainAndPhoneSuccess(state, action)
    case UPDATE_MODEL_SUCCESS:
      return handleUpdateModelSuccess(state, action)
    case UPDATE_COMPANY_INFORMATION_SUCCESS:
      return handleUpdateCompanyInformationSuccess(state, action)
    case GET_USER_SUCCESS:
      return handleGetUserSuccess(state, action)
    case SET_USER_SETTINGS:
      return handleSetUserSettingsSuccess(state, action)
    case SET_DASHBOARD_LANG:
      return handleSetDashboardLang(state, action)
    case LOGOUT_USER:
      return initialStateAuth
    default:
      return state
  }
}

export default auth
