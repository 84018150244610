import { risikaPagesConfigs } from 'pages'
import { RouteConfig } from 'react-router-config'

class FuseUtils {
  static setRoutes(config: RouteConfig) {
    let routes = [...(config.routes || [])]

    if (config.settings || config.auth) {
      routes = routes.map((route) => {
        let auth = config.auth ? [...config.auth] : []
        auth = route.auth ? [...auth, ...route.auth] : auth
        return {
          ...route,
          settings: { ...config.settings, ...route.settings },
          auth,
        }
      })
    }

    return [...routes]
  }

  static generateRoutesFromConfigs(configs: RouteConfig[]) {
    let allRoutes: RouteConfig[] = []
    configs.forEach((config) => {
      allRoutes = [...allRoutes, ...this.setRoutes(config)]
    })
    return allRoutes
  }
}

const routeConfigs = [
  ...risikaPagesConfigs, // Risika content
]

export const routes = [...FuseUtils.generateRoutesFromConfigs(routeConfigs)]

export default routes
