import * as React from 'react'
import { usePrevious } from 'react-use'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useShowButtons } from 'services/hooks'
import ResizableButtonContainer from 'components-new/layouts/ResizableButtonContainer'
import {
  Breadcrumbs,
  Stack,
  Paper,
  IconButton,
  Tooltip,
  Typography,
  StackProps,
  useMediaQuery,
} from '@mui/material'
import { Theme } from '@mui/material/styles'
import { ConditionallyRender } from 'components'
import { addHistoryEntry, goBackInHistory } from 'store_deprecated'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { useStyles } from '../styles/history-header'
import { ReduxRootState } from 'store_deprecated/types'
import { Icon } from '@fortawesome/fontawesome-svg-core'
import {
  faTimes,
  faBuilding,
  faUserTie,
  faFile,
  faSitemap,
} from '@fortawesome/free-solid-svg-icons'
import { KeyboardDoubleArrowLeft, ChevronLeft } from '@mui/icons-material'

type HistoryHeaderProps = {
  title: string
  historyKey: string
  country?: string
  location: string
  children?: JSX.Element[]
  subheader?: string | null
  secondaryActions?: string[]
  titleExtraProps?: StackProps
  onChangeCallback?: () => boolean
}

type HandleIconProps = {
  crumb: Crumb
}

type Crumb = {
  location: string
  country?: string
  historyKey: string
}

/**
 *
 * @param {title} string
 * Title of the page
 * @param {historyKey} string
 * This is used to add the page to the artificial history
 * @param {country} string
 * Not required. If the page is related to a country, this should be passed
 * @param {location} string
 * This is the same location you add in services/navigation.
 * @param {children} ReactNode
 * !!!important All children should be passed as separate components and they should not be wrapped by a div or fragment or anything else.
 * Each child should have a ref and a key prop. They are used to calculate the width of the buttons container and without them the page flickers on certain resolutions.
 * @param {subheader} string
 * Not required. If the page has a subheader, this should be passed
 * @param {secondaryActions} array
 * You can use secondaryActions in case you have some children that need to always be rendered in the ⋮ drawer. Just add their keys in an array and the History will hide them.
 * @returns
 *
 * This component requires children to be passed as separate components and they should not be wrapped by a div or fragment or anything else.
 * Each button should have a ref and a key prop.
 *
 */
const HistoryHeader = ({
  title,
  historyKey,
  country = '',
  location,
  children,
  subheader = '',
  titleExtraProps = {},
  secondaryActions = [],
  onChangeCallback,
}: HistoryHeaderProps) => {
  const classes = useStyles()
  const { history } = useSelector((state: ReduxRootState) => state.risika)
  const dispatch = useDispatch()
  const prevTitle = usePrevious(title)
  const isSmallDevice = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const [isShowhistory, setIsShowHistory] = React.useState(false)
  const buttonsRef = React.useRef(null)
  const titleRef = React.useRef(null)
  const wholeRef = React.useRef(null)
  const routerHistory = useHistory()

  const pages = [
    'home',
    'monitoring',
    'advancedfilters',
    'companyhierarchy',
    'creditpolicy',
    'company-comparison',
    'observational-lists',
    'advance-credit-policy',
  ]

  const shownButtons = useShowButtons({
    mainContainerRef: wholeRef,
    buttonsContainerRef: buttonsRef,
    extraRefs: [titleRef],
    children,
  })

  React.useEffect(() => {
    if (title && prevTitle !== title) {
      if (typeof title === 'string') {
        dispatch(
          addHistoryEntry({
            historyKey,
            location,
            country: country ?? '',
            title,
          })
        )
      } else {
        if (history?.[history?.length - 1]?.title !== historyKey) {
          dispatch(
            addHistoryEntry({
              historyKey,
              location,
              country: country ?? '',
              title: historyKey,
            })
          )
        }
      }
    }
  }, [location, dispatch, country, title, history, prevTitle, historyKey])

  const HandleIcon = ({ crumb }: HandleIconProps) => {
    const location = crumb?.location
    if (location === 'creditcheck') {
      return <FontAwesomeIcon className={classes.iconStyle} icon={faBuilding as Icon} />
    }
    if (location === 'relationoverview') {
      return <FontAwesomeIcon className={classes.iconStyle} icon={faUserTie as Icon} />
    }
    if (location === 'companyhierarchy') {
      return <FontAwesomeIcon className={classes.iconStyle} icon={faSitemap as Icon} />
    }

    return <FontAwesomeIcon className={classes.iconStyle} icon={faFile as Icon} />
  }
  const handleCrumb = (crumb: Crumb) => {
    const handleHistoryLocation = (history: Crumb) => {
      switch (history.historyKey) {
        case 'home':
          return '/home'
        case 'monitoring':
          return '/riskmonitoring'
        case 'advancedfilters':
          return '/advancedfilters'
        case 'company-comparison':
          return '/company-comparison'
        case 'observational-lists':
          return '/observational-lists'
        case 'advance-credit-policy':
          return '/creditpolicy'
        case 'settings':
          return '/settings'
        case 'faq':
          return '/faq'
        default:
          if (history.location === 'companyhierarchy') {
            return `/${history?.location}/${history?.historyKey}`
          } else {
            return `/${history?.location}/${history?.country}/${history?.historyKey}`
          }
      }
    }
    routerHistory.push(handleHistoryLocation(crumb))
    setIsShowHistory(false)
  }

  const handleTitle = (title: string) => {
    if (pages.includes(title)) {
      return <FormattedMessage id={intl.history(title)} />
    } else {
      return title
    }
  }

  const handleHistory = () => {
    if (onChangeCallback) {
      const shouldNavigate = onChangeCallback()
      if (!shouldNavigate) return
    }
    if (history?.length > 2) {
      dispatch(goBackInHistory(routerHistory))
    } else {
      routerHistory.push('/home')
    }
  }
  return (
    <Paper ref={wholeRef} className={classes.container}>
      <Stack
        justifyContent="space-between"
        direction="row"
        alignItems="center"
        flex="1"
        p={2}
      >
        <div ref={titleRef} className={classes.titleContainer}>
          <ConditionallyRender
            condition={history?.length > 2}
            when={
              <ConditionallyRender
                condition={isShowhistory}
                when={
                  <IconButton
                    className={classes.showHistoryIcon}
                    onClick={() => setIsShowHistory(false)}
                  >
                    <FontAwesomeIcon icon={faTimes as Icon} className={classes.icon} />
                  </IconButton>
                }
                otherwise={
                  <IconButton
                    data-cy="show-history-button"
                    sx={{ padding: 2, color: 'grey.600' }}
                    onClick={() => setIsShowHistory(true)}
                  >
                    <KeyboardDoubleArrowLeft sx={{ fontSize: '22px' }} color="inherit" />
                  </IconButton>
                }
              />
            }
          />
          <ConditionallyRender
            condition={!isShowhistory}
            when={
              <>
                <IconButton
                  data-cy="goBackButton"
                  sx={{
                    visibility: history?.length > 1 ? 'visible' : 'hidden',
                    padding: 1,
                    color: 'grey.600',
                  }}
                  onClick={handleHistory}
                >
                  <ChevronLeft sx={{ fontSize: '30px' }} color="inherit" />
                </IconButton>
                <Tooltip
                  style={{ fontSize: '1.5rem' }}
                  placement="top"
                  title={title?.length > 55 ? title : ''}
                >
                  <Stack
                    data-cy="historyHeaderTitle"
                    alignItems="center"
                    sx={{
                      cursor: 'default',
                      whiteSpace: shownButtons === 0 ? undefined : 'nowrap',
                    }}
                    fontWeight="fontWeightHeavy"
                    fontSize={isSmallDevice ? 'h5.fontSize' : 'h4.fontSize'}
                    color="inherit"
                    {...titleExtraProps}
                  >
                    <Typography variant="h3">
                      {title.length > 55 ? `${title?.slice(0, 55)}...` : title}
                    </Typography>
                    {subheader && <Typography variant="h3">{subheader}</Typography>}
                  </Stack>
                </Tooltip>
              </>
            }
            otherwise={
              <Breadcrumbs data-cy="breadcrumbs-bar" className={classes.crumb}>
                {history.map((element) => (
                  <Typography
                    key={element?.historyKey}
                    color="inherit"
                    onClick={() => handleCrumb(element)}
                    className={classes.crumb}
                  >
                    <HandleIcon crumb={element} />
                    {handleTitle(element?.title ?? element?.historyKey)}
                  </Typography>
                ))}
              </Breadcrumbs>
            }
          />
        </div>
        {children && !isShowhistory ? (
          <ResizableButtonContainer
            secondaryActions={secondaryActions}
            ref={buttonsRef}
            shownButtons={shownButtons}
            headerId={historyKey}
          >
            {children}
          </ResizableButtonContainer>
        ) : null}
      </Stack>
    </Paper>
  )
}

export default HistoryHeader
