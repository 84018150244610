import { Divider, Stack, Box } from '@mui/material'
import Section from './widgets/Section'
import FieldTitle from './widgets/FieldTitle'
import ProTips from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/widgets/ActionPanel/widgets/ProTips/ProTips'
import { COMPANY_VAT } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.model'
import CompanyTypeInput from './widgets/CompanyTypeInput/CompanyTypeInput'
import IndustryTypeInput from './widgets/IndustryTypeInput/IndustryTypeInput'
import YearsOfExistenceInput from './widgets/YearsOfExistenceInput/YearsOfExistenceInput'
import NumberOfEmployeesInput from './widgets/NumberOfEmployeesInput/NumberOfEmployeesInput'
import EquityInput from './widgets/EquityInput/EquityInput'
import CompanyVAT from './widgets/CompanyVAT/CompanyVAT'
import { useIntl } from 'react-intl'
import intl from 'localization/components'
import RisikaScoreInput from './widgets/RisikaScoreInput/RisikaScoreInput'
import RejectPeople from './widgets/RejectPeople/RejectPeople'
import RejectCompany from './widgets/RejectCompany/RejectCompany'
import DebtInput from './widgets/DebtInput/DebtInput'
import ShortTermDebtInput from './widgets/ShortTermDebtInput/ShortTermDebtInput'
import ResultAfterTaxInput from './widgets/ResultAfterTaxInput/ResultAfterTaxInput'
import FieldWrapper from './widgets/FieldWrapper/FieldWrapper'
import styles from './AdvanceCreditPolicyPage.styles'
import RegisteredPhoneNumber from './widgets/RegisteredPhoneNumber/RegisteredPhoneNumber'
import { useCountryPolicy } from './CountryPolicyProvider'
import { FraudIndicatorsCTA } from './widgets'

const SwedishPolicy = () => {
  const reactIntl = useIntl()

  return (
    <Stack
      direction="column"
      divider={<Divider orientation="horizontal" />}
      sx={styles.policyContainer}
    >
      <Section
        title={reactIntl.formatMessage({
          id: intl.creditPolicyNew('company_information'),
        })}
      >
        <FieldWrapper>
          <FieldTitle
            title={reactIntl.formatMessage({
              id: intl.creditPolicyNew('company-type'),
            })}
            desc={reactIntl.formatMessage({
              id: intl.creditPolicyNew('company-type-desc'),
            })}
          />
          <CompanyTypeInput />
        </FieldWrapper>
        <FieldWrapper>
          <FieldTitle
            title={reactIntl.formatMessage({
              id: intl.creditPolicyNew('company-years-title'),
            })}
            desc={reactIntl.formatMessage({
              id: intl.creditPolicyNew('company-years-desc'),
            })}
          />
          <YearsOfExistenceInput />
        </FieldWrapper>
        <FieldWrapper isAdvanced>
          <FieldTitle
            title={reactIntl.formatMessage({
              id: intl.creditPolicyNew('industry-type-title'),
            })}
            desc={reactIntl.formatMessage({
              id: intl.creditPolicyNew('industry-type-desc'),
            })}
            isAdvanced
          />
          <IndustryTypeInput />
        </FieldWrapper>
        <FieldWrapper isAdvanced>
          <FieldTitle
            isAdvanced
            title={reactIntl.formatMessage({
              id: intl.creditPolicyNew('number-of-employees-title'),
            })}
            desc={reactIntl.formatMessage({
              id: intl.creditPolicyNew('number-of-employees-desc'),
            })}
          />
          <NumberOfEmployeesInput />
        </FieldWrapper>
      </Section>
      <Section
        title={reactIntl.formatMessage({
          id: intl.creditPolicyNew('risk_indicators'),
        })}
      >
        <FieldWrapper>
          <FieldTitle
            title={reactIntl.formatMessage({
              id: intl.creditPolicyNew('risika-score-title'),
            })}
            desc={reactIntl.formatMessage({
              id: intl.creditPolicyNew('risika-score-desc'),
            })}
          />
          <RisikaScoreInput />
        </FieldWrapper>
      </Section>
      <Section
        title={reactIntl.formatMessage({
          id: intl.creditPolicyNew('fraud_indicators'),
        })}
      >
        <FieldWrapper isAdvanced>
          <FieldTitle
            isAdvanced
            title={reactIntl.formatMessage({
              id: intl.creditPolicy('phone-number-registry-title'),
            })}
          />
          <RegisteredPhoneNumber policyInject={useCountryPolicy} />
        </FieldWrapper>
        <FraudIndicatorsCTA />
      </Section>
      <Section
        title={reactIntl.formatMessage({
          id: intl.creditPolicyNew('people_and_company_search'),
        })}
      >
        <FieldWrapper isAdvanced>
          <FieldTitle
            isAdvanced={true}
            title={reactIntl.formatMessage({
              id: intl.creditPolicyNew('people-id-title'),
            })}
            desc={reactIntl.formatMessage({
              id: intl.creditPolicyNew('people-id-desc'),
            })}
          />
          <RejectPeople />
        </FieldWrapper>
        <FieldWrapper isAdvanced>
          <FieldTitle
            isAdvanced={true}
            title={reactIntl.formatMessage({
              id: intl.creditPolicyNew('company-id-title'),
            })}
            desc={reactIntl.formatMessage({
              id: intl.creditPolicyNew('company-id-desc'),
            })}
          />
          <RejectCompany />
        </FieldWrapper>
      </Section>
      <Section
        title={reactIntl.formatMessage({
          id: intl.creditPolicyNew('financial_indicators'),
        })}
      >
        <FieldWrapper>
          <FieldTitle
            title={reactIntl.formatMessage({
              id: intl.creditPolicyNew('equity'),
            })}
            desc={reactIntl.formatMessage({
              id: intl.creditPolicyNew('equity-desc'),
            })}
          />
          <EquityInput />
        </FieldWrapper>
        <FieldWrapper isAdvanced>
          <FieldTitle
            isAdvanced={true}
            title={reactIntl.formatMessage({
              id: intl.creditPolicyNew('company-vat-title'),
            })}
            desc={reactIntl.formatMessage({
              id: intl.creditPolicyNew('company-vat-desc'),
            })}
          />
          <CompanyVAT />
          <Box mt={4}>
            <ProTips
              tip={reactIntl.formatMessage({
                id: intl.creditPolicyNew(COMPANY_VAT),
              })}
            />
          </Box>
        </FieldWrapper>
        <FieldWrapper isAdvanced>
          <FieldTitle
            isAdvanced
            title={reactIntl.formatMessage({
              id: intl.creditPolicy('POLICY_DEBT'),
            })}
            desc={reactIntl.formatMessage({
              id: intl.creditPolicyNew('debt-desc'),
            })}
          />
          <DebtInput />
        </FieldWrapper>
        <FieldWrapper isAdvanced>
          <FieldTitle
            isAdvanced
            title={reactIntl.formatMessage({
              id: intl.creditPolicy('POLICY_SHORT_TERM_DEBT'),
            })}
            desc={reactIntl.formatMessage({
              id: intl.creditPolicyNew('short-term-debt-desc'),
            })}
          />
          <ShortTermDebtInput />
        </FieldWrapper>
        <FieldWrapper isAdvanced>
          <FieldTitle
            isAdvanced
            title={reactIntl.formatMessage({
              id: intl.creditPolicy('POLICY_PROFIT_LOSS'),
            })}
            desc={reactIntl.formatMessage({
              id: intl.creditPolicyNew('result-after-tax-desc'),
            })}
          />
          <ResultAfterTaxInput />
        </FieldWrapper>
      </Section>
    </Stack>
  )
}

export default SwedishPolicy
