import getQueryString from 'utils-new/getQueryString'

export const getFeatureFlag = (featureFlag: { state: boolean; queryKey: string }) => {
  const { state, queryKey } = featureFlag

  if (process.env.NODE_ENV === 'production') {
    return state
  }

  const queryObject = getQueryString() || {}

  if (queryObject[queryKey] === 'true') {
    return true
  } else if (queryObject[queryKey] === 'false') {
    return false
  }

  return state
}
