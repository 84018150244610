export const countries = ['DK', 'SE', 'NO', 'FI']
export const minRows = 20
export const DOMAIN = '[RISIKA]'
export const ACTIVE = 'ACTIVE'
export const DECREE = 'DECREE'
export const NORWEGIAN_PAYMENT_REMARK_COLLECTOR = 'Intrum'
export const RISIKA_PRICING_PAGE = 'https://risika.com/pricing'
export const countryMap = {
  DK: 'DENMARK',
  SE: 'SWEDEN',
  NO: 'NORWAY',
  FI: 'FINLAND',
}
