import React from 'react'
import { useCountryPolicy } from '../../CountryPolicyProvider'
import DanishPolicy from '../../DanishPolicy'
import SwedishPolicy from '../../SwedishPolicy'
import NorwegianPolicy from '../../NorwegianPolicy'
import FinnishPolicy from '../../FinnishPolicy'
import ErrorScene from '../ErrorScene/ErrorScene'

const AdvancedCreditPolicy = () => {
  const { selectedCountry, isPolicyFetching, isPolicyError, policyError } =
    useCountryPolicy()

  if (isPolicyFetching) return null

  if (isPolicyError) {
    const { response } = policyError || {}
    const isNoPolicySetError =
      response?.status === 400 && response.data.error === 'No credit policy is set'

    if (!isNoPolicySetError) {
      return <ErrorScene height={300} />
    }
  }

  switch (selectedCountry) {
    case 'dk':
      return <DanishPolicy />
    case 'se':
      return <SwedishPolicy />
    case 'no':
      return <NorwegianPolicy />
    case 'fi':
      return <FinnishPolicy />
  }
}

export default AdvancedCreditPolicy
