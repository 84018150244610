import { authRoles } from 'components/auth'
import FraudIndicators from './FraudIndicators'

export const FraudIndicatorsPageConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  auth: authRoles.user,
  routes: [
    {
      path: '/fraud-indicators',
      component: FraudIndicators,
    },
  ],
}
