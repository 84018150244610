import { Box, Stack, Typography, Skeleton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
// @ts-ignore
import HubspotForm from 'react-hubspot-form'
import intl from 'localization/components'
import { FormattedMessage, useIntl } from 'react-intl'

const main = {
  borderRadius: '6px',
  display: 'flex',
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  overflow: 'hidden',
  transform: 'translate(-50%, -50%)',
  width: '600px',
  backgroundColor: 'white',
}

const sidePane = {
  backgroundColor: 'primary.900',
  position: 'relative',
  flex: '0 0 176px',
  img: {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70px',
  },
}

const list = {
  marginBottom: 32,
  listStyle: 'none',
  paddingLeft: '16px',
}

const listItem = {
  backgroundImage: 'url("/risika/circleTick.svg")',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: '0 3px',
  paddingLeft: '24px',
  marginTop: '8px',
}

const mainPane = {
  padding: '80px 32px 32px',
}

const closeBtn = {
  position: 'absolute',
  top: '20px',
  right: '20px',
  '&:hover': {
    cursor: 'pointer',
  },
}

type PlanRestrictionModalCanvasProps = {
  formId: string
  onClose: () => void
  title: string
  bulletPoints: string[]
  prompt: string
}

const PlanRestrictionModalCanvas = (props: PlanRestrictionModalCanvasProps) => {
  const { formId, onClose, title, bulletPoints, prompt } = props
  return (
    <Box sx={main}>
      <Box sx={sidePane}>
        <img src={'/risika/logoshield-blue.svg'} alt="" />
      </Box>
      <Box sx={mainPane}>
        <CloseIcon onClick={onClose} sx={closeBtn} fontSize="large" />
        <Typography variant="h5" marginBottom={3}>
          <FormattedMessage id={intl.creditPolicyNew(title)} />
        </Typography>

        <ul style={list}>
          {bulletPoints.map((string) => {
            return (
              <li style={listItem} key={string}>
                <Typography variant="body2">
                  <FormattedMessage id={intl.creditPolicyNew(string)} />
                </Typography>
              </li>
            )
          })}
        </ul>
        <Typography marginBottom={3} variant="body1">
          <FormattedMessage id={intl.creditPolicyNew(prompt)} />
        </Typography>

        <HubspotForm
          portalId="5137178"
          formId={formId}
          loading={
            <Stack spacing={5} marginTop={2}>
              <Stack spacing={2}>
                <Skeleton variant="rounded" width="20%" height={20} animation="wave" />
                <Skeleton variant="rounded" width="100%" height={40} animation="wave" />
              </Stack>
              <Stack spacing={2}>
                <Skeleton variant="rounded" width="40%" height={20} animation="wave" />
                <Skeleton variant="rounded" width="100%" height={40} animation="wave" />
              </Stack>
              <Skeleton variant="rounded" width="50%" height={40} animation="wave" />
            </Stack>
          }
        />
      </Box>
    </Box>
  )
}

export default PlanRestrictionModalCanvas
