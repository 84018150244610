import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'

import { setMapsCache } from 'store_deprecated/cache'

import { handleAddressField } from 'utils'
import axios from 'axios'

import { env } from 'services/env'
import { useAccountStatus } from 'services/queries'
import { CircularProgress } from '@mui/material'

const containerStyle = {
  width: '100%',
  height: '40vh',
}

const PRECISION = {
  ADDRESS: 'address',
  STREET: 'street',
  ZIPCODE: 'zipcode',
  MUNICIPALITY: 'municipality',
  COUNTY: 'county',
  COUNTRY: 'country',
}

const MapComponent = ({ address }) => {
  const dispatch = useDispatch()
  const { maps } = useSelector((state) => state.risika.cache)
  const [center, setCenter] = React.useState(null)
  const addressString = handleAddressField(address)
  const accountStatusQuery = useAccountStatus()

  React.useEffect(() => {
    const { coordinates } = address || {}
    if (coordinates && coordinates?.precision === PRECISION.ADDRESS) {
      const { lat, lng } = address.coordinates

      setCenter({ lat, lng })
    } else {
      if (maps?.[addressString]?.lat) {
        setCenter(maps?.[addressString])
      } else {
        axios
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?address=${addressString}&key=${env.GOOGLE_MAPS_KEY}`
          )
          .then((res) => {
            const { results } = res?.data
            const { lat, lng } = results?.[0]?.geometry?.location
            setCenter({ lat, lng })
            dispatch(setMapsCache(addressString, lat, lng))
          })
          .catch((err) => {
            console.log(
              'Most likely you have used all the available free quota for today.',
              err
            )
          })
      }
    }
  }, [address, addressString, dispatch, maps])

  const onUnmount = React.useCallback(function callback(map) {
    setCenter(null)
  }, [])

  if (accountStatusQuery.isLoading) {
    return <CircularProgress />
  }

  if (accountStatusQuery.data?.subscription_plan === 'FREEMIUM') {
    return null
  }

  return (
    <div data-cy="map">
      <LoadScript googleMapsApiKey={env.GOOGLE_MAPS_KEY}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          // onLoad={onLoad}
          onUnmount={onUnmount}
          zoom={14}
        >
          <Marker position={center} />
        </GoogleMap>
      </LoadScript>
    </div>
  )
}

export default React.memo(MapComponent)
