import React, { ReactElement } from 'react'
import { Typography, Box, useMediaQuery, Theme } from '@mui/material'
import { default as cx } from 'classnames'
import { ConditionallyRender, ShowHideAllWrapper, CopyItem } from 'components'
import moment from 'moment'
import { useStyles } from '../styles/GeneralCompanyInformation.styles'
import InfoButtonLinkComponent from './InfoButtonLinkComponent'

export type HistoryBaseType = {
  data: string
  valid_from?: string
  valid_to?: string
}

type SimpleEntryProps<
  ContentType extends React.ReactNode,
  HistoryType extends HistoryBaseType
> = {
  title: ReactElement<any, any>
  content: ContentType[] // pass in here
  classNameTitle: string
  isHistory: boolean
  historyData: HistoryType[] // pass in here
  setIsShowAllHistory?: () => void
  isShowAllHistory?: boolean
  isCopyable?: string
  dataCy?: string
  infoLink?: string
  toolTip?: string
}

const SimpleEntry = <
  ContentType extends React.ReactNode,
  HistoryType extends HistoryBaseType
>(
  props: SimpleEntryProps<ContentType, HistoryType>
) => {
  const {
    title,
    content,
    classNameTitle,
    isHistory = false,
    historyData = [],
    setIsShowAllHistory,
    isShowAllHistory = false,
    isCopyable = '',
    dataCy,
    infoLink,
    toolTip,
  } = props
  const classes = useStyles()

  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  return (
    <div data-cy={dataCy} className={classes.container}>
      <Typography className={cx(classes.sub_title, classNameTitle)} variant="subtitle2">
        {title}
        <InfoButtonLinkComponent infoLink={infoLink} toolTipTranslationId={toolTip} />
      </Typography>

      {content?.map((content) => (
        <React.Fragment key={content?.toString()}>
          <Typography
            component={'div'}
            style={{
              flex: isSmallScreen ? '3' : '3 1 0%',
              alignSelf: 'baseline',
            }}
            variant="body2"
          >
            {content}
            {isCopyable.length ? (
              <Box
                component="div"
                role="button"
                tabIndex={0}
                sx={{
                  '&:focus': {
                    borderColor: '#54BDCB',
                    borderWidth: '2px',
                    borderRadius: '4px',
                    borderStyle: 'solid',
                  },
                }}
                display="inline"
                ml={2}
              >
                <CopyItem item={isCopyable} />
              </Box>
            ) : null}
            <ConditionallyRender
              condition={isHistory}
              when={historyData.map((historyUnit, i) => (
                <div key={historyUnit.data} style={{ marginTop: '1rem' }}>
                  <ShowHideAllWrapper
                    isHistory
                    setIsShowAll={setIsShowAllHistory}
                    isShowAll={isShowAllHistory}
                    amountOfShowedItems={0}
                    iteration={i}
                    dataLength={historyData?.length}
                  >
                    <React.Fragment key={i}>
                      <Typography variant="body2" sx={{ color: 'grey.600' }}>
                        {historyUnit?.data}
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'grey.600' }}>
                        {`(${moment(historyUnit?.valid_from).format('DD.MM.YYYY')}  ${
                          historyUnit?.valid_to
                            ? `-${moment(historyUnit?.valid_to).format(' DD.MM.YYYY')}`
                            : ''
                        })`}
                      </Typography>
                    </React.Fragment>
                  </ShowHideAllWrapper>
                </div>
              ))}
            />
          </Typography>
        </React.Fragment>
      ))}
    </div>
  )
}

export default SimpleEntry
