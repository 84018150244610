import { AvailableCountries, AvailableCountriesCapital } from 'globalTypes'

export const renderedComponents = {
  DK: [
    'generalCompanyInformation',
    'keyFinancialFigures',
    'relevantFinancialRatios',
    'owners',
    'management',
    'map',
    'monitorCompany',
    'shareReport',
    'risikasEstimation',
    'performance',
    'creditPolicy',
    'fraudIndicators',
    'companyHighlights',
    'statstidende',
  ],
  SE: [
    'generalCompanyInformation',
    'keyFinancialFigures',
    'relevantFinancialRatios',
    'owners',
    'management',
    'map',
    'monitorCompany',
    'shareReport',
    'risikasEstimation',
    'performance',
    'creditPolicy',
    'fraudIndicators',
    'companyHighlights',
  ],
  NO: [
    'generalCompanyInformation',
    'keyFinancialFigures',
    'relevantFinancialRatios',
    'owners',
    'management',
    'map',
    'monitorCompany',
    'shareReport',
    'risikasEstimation',
    'performance',
    'creditPolicy',
    'fraudIndicators',
    'companyHighlights',
    'norwegianPaymentRemarks',
  ],
  UK: ['generalCompanyInformation', 'map', 'risikasEstimation', 'keyFinancialFigures'],
  FI: [
    'generalCompanyInformation',
    'keyFinancialFigures',
    'relevantFinancialRatios',
    'risikasEstimation',
    'owners',
    'management',
    'map',
    'monitorCompany',
    'shareReport',
    'performance',
    'creditPolicy',
    'fraudIndicators',
    'companyHighlights',
    // 'statstidende'
  ],
  DE: ['generalCompanyInformation', 'map', 'management', 'keyFinancialFigures'],
}

export const isComponentRendered = (
  key: string,
  country: AvailableCountriesCapital | AvailableCountries | null
): boolean => {
  const countries = ['DK', 'SE', 'NO', 'UK', 'FI', 'DE']

  if (!country) throw new Error('Country that doesnt exist')
  if (!countries?.includes(country.toUpperCase())) {
    throw new Error('Country that doesnt exist')
  }
  return Boolean(
    renderedComponents[country.toUpperCase() as keyof typeof renderedComponents].includes(
      key
    )
  )
}

export const newtonAllowedCountries = ['dk', 'no']
