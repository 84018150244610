import { Divider, Stack } from '@mui/material'
import styles from './styles'

const DanishFraudIndicators = () => {
  return (
    <Stack
      direction="column"
      divider={<Divider orientation="horizontal" />}
      sx={styles.fraudIndicatorsContainer}
      data-cy="fraud-indicators-danish"
    />
  )
}

export default DanishFraudIndicators
