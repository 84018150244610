export const ON_EDGES_CHANGE = 'on-edges-change'
export const ACTION_PANEL_TOGGLE = 'action-panel-toggle'
export const SET_ACTION_PANEL_CONTENT = 'set-action-panel-content'
export const SET_NODE_API_DATA = 'set-node-content'
export const ADD_NODE_API_DATA = 'add-node-api-data'
export const CANCEL_NODE = 'cancel-node'
export const DELETE_NODE = 'delete_node'

/** ***********New Stff */
export const ADD_NODES_AND_EDGES = 'add_nodes_and_edges'
export const UNDO_EDIT = 'undo_edit'
export const ADD_FIRST_NODE = 'add_first_node'
export const ON_NODES_CHANGE = 'on_nodes_change'
export const CREATE_EMPTY_NODE = 'create_empty_node'
export const ALLOW_ONLY_ACTIVE_COMPANIES = 'allow_only_active_companies'
export const ADD_UPDATE_HIGHLIGHTED_NODE = 'add_update_highlighted_node'
export const DELETE_HIGHLIGHTED_NODE = 'delete_highlighted_node'
export const UPDATE_CATEGORY = 'update_category'
export const CREATE_NODE_BETWEEN_NODES = 'create_node_between_nodes'
