import * as React from 'react'
import { useCreditPolicyUpdate } from 'services/queries'
import { handleUpdatePolicy } from '../PolicyBuilder.controller'
import {
  BuilderDispatch,
  BuilderEdge,
  BuilderNode,
  BuilderProviderProps,
  BuilderState,
  GraphState,
  PolicyBuilderParams,
} from '../PolicyBuilder.types'
import { actions } from './actions'
import { builderReducer } from './reducer'
import { queryFind } from '../../../../../services/queries'
import { ECPDataType } from '../../../../../services/api/endpoints/risikaAPI/getECPDataStructure'
import { useMemo } from 'react'
import { actionPanelIconsByKeys } from '../widgets/ActionPanel/widgets/ActionPanelRuleList/ActionPanelRuleList.model'
import { useBuilderParams } from '../PolicyBuilder.hooks'

const BuilderStateContext = React.createContext<
  | {
      state: BuilderState
      dispatch: BuilderDispatch
      actions: ReturnType<typeof actions>
      variableIcons: Record<string, keyof typeof actionPanelIconsByKeys>
    }
  | undefined
>(undefined)

const initialNodes: BuilderNode[] = []
const initialEdges: BuilderEdge[] = []

const defaultState = {
  nodes: initialNodes,
  edges: initialEdges,
  currHighestId: initialNodes.length,
  actionPanelData: { open: false, nodeId: '' },
  actionPanelContent: '',
  highlightedNodes: {},
  history: [] as GraphState[],
} as const

function BuilderProvider({ children }: BuilderProviderProps) {
  const [state, dispatch] = React.useReducer(builderReducer, defaultState)
  const dataStructure = queryFind('useECPDataStructure') as ECPDataType

  const variableIcons: Record<string, keyof typeof actionPanelIconsByKeys> =
    useMemo(() => {
      if (!dataStructure) return {}
      return dataStructure.reduce((acc, curr) => {
        if (!curr) return acc
        curr.template?.forEach((item) => {
          if (!item.template_data) return acc
          const variableName: string = item.template_data.api_key
          const variableCategoryKey = curr.data?.variable_category_key

          const icon = {
            [variableName]:
              actionPanelIconsByKeys[
                variableCategoryKey as keyof typeof actionPanelIconsByKeys
              ],
          }
          Object.assign(acc, icon)
        })
        return acc
      }, {})
    }, [dataStructure])

  // NOTE: you *might* need to memoize this value
  const value = { state, actions: actions(dispatch), dispatch, variableIcons }
  return (
    <BuilderStateContext.Provider value={value}>{children}</BuilderStateContext.Provider>
  )
}

function useBuilder() {
  const context = React.useContext(BuilderStateContext)
  if (context === undefined) {
    throw new Error('useBuilder must be used within a BuilderProvider')
  }
  return context
}

export { BuilderProvider, useBuilder }
