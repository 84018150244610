import { Stack } from '@mui/material'
import { HistoryHeader } from 'components'
import intl from 'localization/components'
import { useIntl } from 'react-intl'
import FraudIndicatorsTabs from './widgets/FraudIndicatorsTabs'
import FraudIndicatorsContent from './widgets/FraudIndicatorsContent'
import FraudIndicatorsProvider from './FraudIndicatorsProvider'

const FraudIndicatorsPage = () => {
  const { messages } = useIntl()
  return (
    <FraudIndicatorsProvider>
      <Stack>
        <HistoryHeader
          title={messages[intl.navigation('fraud-indicators')] as string}
          location="fraud-indicators"
          historyKey="fraud-indicators"
        />
        <FraudIndicatorsTabs />
        <FraudIndicatorsContent />
      </Stack>
    </FraudIndicatorsProvider>
  )
}

export default FraudIndicatorsPage
