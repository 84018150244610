import { SxProps } from '@mui/system'
import { theme } from 'styles-new/mui5-transition/theme'

export const promotionContainer: SxProps = {
  border: `1px solid ${theme.palette.primary.main}`,
  height: 60,
  display: 'flex',
  flexDirection: 'row',
  borderRadius: theme.spacing(1),
  cursor: 'pointer',
}

export const iconContainer: SxProps = {
  bgcolor: theme.palette.primary.main,
  width: 60,
  height: '100%',
  textAlign: 'center',
  alignItems: 'center',
  paddingTop: '12px',
}

export const promotionText: SxProps = {
  margin: 'auto',
}
