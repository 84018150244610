export const navigation = {
  observationalLists: (listId, tableFilterId, tableFilterCategory, tableFilterValue) => {
    // Because listId can actually also be 0
    if (listId !== 0 && !listId) {
      return '/observational-lists'
    }
    if (!tableFilterId || !tableFilterCategory) {
      return `/observational-lists/${listId}`
    }
    if (!tableFilterValue) {
      return `/observational-lists/${listId}/${tableFilterId}/${tableFilterCategory}`
    }
    return `/observational-lists/${listId}/${tableFilterId}/${tableFilterCategory}/${tableFilterValue}`
  },
  companyComparison: (localId) => {
    const id = localId?.id
    const country = localId?.country
    if (!id || !country) {
      return '/company-comparison'
    }
    return `/company-comparison/${country}/${id}`
  },
  creditCheck: (localId) => {
    if (!localId) {
      return '/creditcheck'
    }
    let path = localId?.country ? `${localId?.country.toLowerCase()}` : `dk`
    path = `${path}/${localId?.hash ?? localId?.id}`
    return `/creditcheck/${path}`
  },
  relationOverview: (localId) => {
    if (!localId) {
      return '/relationoverview'
    }
    const path = localId?.country
      ? `${localId?.country.toLowerCase()}/${localId.id}`
      : `dk/${localId}`
    return `/relationoverview/${path}`
  },
  companyHierarchy: (localId) => {
    const id = localId?.id
    const country = localId?.country
    if (!id || !country) {
      return '/companyhierarchy'
    }
    return `/companyhierarchy/${country}/${id}`
  },
  home: () => {
    return '/home'
  },
  customerManagement: () => {
    return '/customermanagement'
  },
  riskMonitoring: () => {
    return '/riskmonitoring'
  },
  creditPolicy: () => {
    return '/creditpolicy'
  },
  fraudIndicators: () => {
    return '/fraud-indicators'
  },
  enterpriseCreditPolicy: () => {
    return '/enterprise-credit-policy'
  },
  creditPolicyBuilder: ({
    country,
    name,
    createActiveRule,
    description = '',
    policyId,
  }) => {
    if (country) {
      return `/credit-policy-builder/${country}/${encodeURIComponent(
        name
      )}/${createActiveRule}/${policyId ?? 'null'}/${description}`
    }
    return '/credit-policy-builder'
  },
  filtering: () => {
    return '/advancedfilters'
  },
  settings: () => {
    return '/settings'
  },
  faq: () => {
    return '/faq'
  },
  register: () => {
    return '/register'
  },
  auth: {
    prefix: '/auth',
    login: () => {
      return '/auth/login'
    },
    forgotPassword: (token) => {
      return `/auth/forgot-password/${token ?? ''}`
    },
    register: () => {
      return '/auth/register'
    },
    verifyAccount: (token) => {
      return `/auth/account/verify/${token}`
    },
    verifyAccount2: (token) => {
      // This is temporary compatibility fix for the lack of communication
      return `/auth/verify/${token}`
    },
    frictionlessRegister: (email) => {
      return `/auth/frictionless/${email}`
    },
    accountDeactivated: () => {
      return '/auth/account-deactivated'
    },
    logout: () => {
      return '/auth/logout'
    },
    sso: () => {
      return '/auth/sso'
    },
  },
}
