import { Modal } from '@mui/material'

import PlanRestrictionModalCanvas from './PlanRestrictionModalCanvas'
import { FRAUD_INDICATORS_FORM_ID } from './hubspotformIdconstants'

type FraudIndicatorsModalProps = {
  isOpen: boolean
  onClose: () => void
}

const TITLE = 'upgrade-advanced-fraud-indicators'

const BULLET_ONE = 'additional-fraud-variables'
const BULLET_TWO = 'limit-fraud-risk'
const BULLET_THREE = 'monitor-fraud-indicators'
const BULLET_POINTS = [BULLET_ONE, BULLET_TWO, BULLET_THREE]

const PROMPT = 'talk-to-sales-rep'

export const FraudIndicatorsModal = (props: FraudIndicatorsModalProps) => {
  const { isOpen, onClose } = props

  return (
    <Modal open={isOpen} data-cy="fraud-indicators-modal">
      <PlanRestrictionModalCanvas
        formId={FRAUD_INDICATORS_FORM_ID}
        onClose={onClose}
        title={TITLE}
        bulletPoints={BULLET_POINTS}
        prompt={PROMPT}
      />
    </Modal>
  )
}
