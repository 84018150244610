import { SxProps } from '@mui/system'

export const modalContainer: SxProps = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  borderRadius: 6,
  display: 'flex',
  flexDirection: 'row',
}

export const closeIcon: SxProps = {
  marginRight: 'auto',
  height: 24,
  width: 24,
  cursor: 'pointer',
}

export const contentBox: SxProps = {
  width: '100%',
  height: '100%',
  bgcolor: 'white',
  pr: 6,
  pl: 6,
  pt: 9,
  pb: 8,
}

export const title: SxProps = {
  variant: 'h4',
  mb: 4,
}

export const buttonPrimary: SxProps = {
  marginTop: 4,
  width: '100%',
  cursor: 'pointer',
}
