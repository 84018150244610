import React from 'react'
import { Tooltip, IconButton, SxProps } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { FormattedMessage } from 'react-intl'
import intl from 'localization/components'
import { useStyles } from '../styles/GeneralCompanyInformation.styles'

interface InfoButtonLinkProps {
  infoLink?: string
  toolTipTranslationId?: string
}

const InfoButtonLinkComponent = (props: InfoButtonLinkProps) => {
  const { infoLink, toolTipTranslationId } = props

  const classes = useStyles()

  const Icon = (
    <IconButton>
      <InfoOutlinedIcon sx={classes.iconButton as SxProps} />
    </IconButton>
  )

  return (
    <>
      {infoLink && (
        <a href={infoLink} target="_blank" rel="noreferrer">
          {Icon}
        </a>
      )}
      {toolTipTranslationId && (
        <Tooltip
          placement="top"
          title={<FormattedMessage id={intl.companyInfo(toolTipTranslationId || '')} />}
        >
          {Icon}
        </Tooltip>
      )}
    </>
  )
}

export default InfoButtonLinkComponent
