import { Divider, Stack } from '@mui/material'
import Section from './widgets/Section'
import FieldTitle from './widgets/FieldTitle'
import ProTips from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/widgets/ActionPanel/widgets/ProTips/ProTips'
import { COMPANY_VAT } from 'pages/enterprise-credit-policy/widgets/PolicyBuilder/PolicyBuilder.model'
import CompanyTypeInput from './widgets/CompanyTypeInput/CompanyTypeInput'
import IndustryTypeInput from './widgets/IndustryTypeInput/IndustryTypeInput'
import YearsOfExistenceInput from './widgets/YearsOfExistenceInput/YearsOfExistenceInput'
import { useCountryPolicy } from './CountryPolicyProvider'
import NumberOfEmployeesInput from './widgets/NumberOfEmployeesInput/NumberOfEmployeesInput'
import EquityInput from './widgets/EquityInput/EquityInput'
import CompanyVAT from './widgets/CompanyVAT/CompanyVAT'
import { useIntl } from 'react-intl'
import intl from 'localization/components'
import PrepaidPhoneInput from './widgets/PrepaidPhoneInput/PrepaidPhoneInput'
import RegisteredPhoneNumber from './widgets/RegisteredPhoneNumber/RegisteredPhoneNumber'
import DistanceToCEO from './widgets/DistanceToCEO/DistanceToCEO'
import RisikaScoreInput from './widgets/RisikaScoreInput/RisikaScoreInput'
import ConnectedBankruptcies from './widgets/ConnectedBankruptcies/ConnectedBankruptcies'
import ConnectedBankruptciesLong from './widgets/ConnectedBankruptciesLong/ConnectedBankruptciesLong'
import AuditorIdInput from './widgets/AuditorIdInput/AuditorIdInput'
import LevelOfAuditorAssistances from './widgets/LevelOfAuditorAssistances/LevelOfAuditorAssistances'
import RejectPeople from './widgets/RejectPeople/RejectPeople'
import RejectCompany from './widgets/RejectCompany/RejectCompany'
import FieldWrapper from './widgets/FieldWrapper/FieldWrapper'
import styles from './AdvanceCreditPolicyPage.styles'
import { Box } from '@mui/material/'
import { FraudIndicatorsCTA } from './widgets'
const DanishPolicy = () => {
  const reactIntl = useIntl()

  return (
    <Stack
      bgcolor="green"
      direction="column"
      divider={<Divider orientation="horizontal" />}
      sx={styles.policyContainer}
    >
      <Section
        title={reactIntl.formatMessage({
          id: intl.creditPolicyNew('company_information'),
        })}
      >
        <FieldWrapper>
          <FieldTitle
            title={reactIntl.formatMessage({
              id: intl.creditPolicyNew('company-type'),
            })}
            desc={reactIntl.formatMessage({
              id: intl.creditPolicyNew('company-type-desc'),
            })}
          />
          <CompanyTypeInput />
        </FieldWrapper>
        <FieldWrapper>
          <FieldTitle
            title={reactIntl.formatMessage({
              id: intl.creditPolicyNew('company-years-title'),
            })}
            desc={reactIntl.formatMessage({
              id: intl.creditPolicyNew('company-years-desc'),
            })}
          />
          <YearsOfExistenceInput />
        </FieldWrapper>
        <FieldWrapper isAdvanced>
          <FieldTitle
            title={reactIntl.formatMessage({
              id: intl.creditPolicyNew('industry-type-title'),
            })}
            desc={reactIntl.formatMessage({
              id: intl.creditPolicyNew('industry-type-desc'),
            })}
            isAdvanced
          />
          <IndustryTypeInput />
        </FieldWrapper>
        <FieldWrapper isAdvanced>
          <FieldTitle
            isAdvanced
            title={reactIntl.formatMessage({
              id: intl.creditPolicyNew('number-of-employees-title'),
            })}
            desc={reactIntl.formatMessage({
              id: intl.creditPolicyNew('number-of-employees-desc'),
            })}
          />
          <NumberOfEmployeesInput />
        </FieldWrapper>
      </Section>
      <Section
        title={reactIntl.formatMessage({
          id: intl.creditPolicyNew('risk_indicators'),
        })}
      >
        <FieldWrapper>
          <FieldTitle
            title={reactIntl.formatMessage({
              id: intl.creditPolicyNew('risika-score-title'),
            })}
            desc={reactIntl.formatMessage({
              id: intl.creditPolicyNew('risika-score-desc'),
            })}
          />
          <RisikaScoreInput />
        </FieldWrapper>
        <FieldWrapper isAdvanced>
          <FieldTitle
            isAdvanced
            title={reactIntl.formatMessage({
              id: intl.creditPolicyNew('audit-level-title'),
            })}
            desc={reactIntl.formatMessage({
              id: intl.creditPolicyNew('audit-level-desc'),
            })}
          />
          <LevelOfAuditorAssistances policyInject={useCountryPolicy} />
        </FieldWrapper>
        <FieldWrapper isAdvanced>
          <FieldTitle
            isAdvanced
            title={reactIntl.formatMessage({
              id: intl.creditPolicy('bankruptcy-short-limit-title'),
            })}
            desc={reactIntl.formatMessage({
              id: intl.creditPolicyNew('bankruptcy-short-limit-desc'),
            })}
          />
          <ConnectedBankruptcies policyInject={useCountryPolicy} />
        </FieldWrapper>
        <FieldWrapper isAdvanced>
          <FieldTitle
            isAdvanced
            title={reactIntl.formatMessage({
              id: intl.creditPolicy('bankruptcy-long-limit-title'),
            })}
            desc={reactIntl.formatMessage({
              id: intl.creditPolicyNew('bankruptcy-long-limit-desc'),
            })}
          />
          <ConnectedBankruptciesLong policyInject={useCountryPolicy} />
        </FieldWrapper>
        <FieldWrapper isAdvanced>
          <FieldTitle
            isAdvanced
            title={reactIntl.formatMessage({
              id: intl.creditPolicyNew('auditor-id-title'),
            })}
          />
          <AuditorIdInput policyInject={useCountryPolicy} />
        </FieldWrapper>
      </Section>
      <Section
        title={reactIntl.formatMessage({
          id: intl.creditPolicyNew('fraud_indicators'),
        })}
      >
        <FieldWrapper isAdvanced>
          <FieldTitle
            isAdvanced
            title={reactIntl.formatMessage({
              id: intl.creditPolicy('distance-to-ceo-title'),
            })}
            desc={reactIntl.formatMessage({
              id: intl.creditPolicyNew('distance-to-ceo-desc'),
            })}
          />
          <DistanceToCEO policyInject={useCountryPolicy} />
          <Box mt={4}>
            <ProTips
              tip={reactIntl.formatMessage({
                id: intl.creditPolicyNew('dk_tips_distance_to_ceo'),
              })}
            />
          </Box>
        </FieldWrapper>
        <FieldWrapper isAdvanced>
          <FieldTitle
            isAdvanced
            title={reactIntl.formatMessage({
              id: intl.creditPolicyNew('prepaid-phone'),
            })}
          />
          <PrepaidPhoneInput policyInject={useCountryPolicy} />
        </FieldWrapper>
        <FieldWrapper isAdvanced>
          <FieldTitle
            isAdvanced
            title={reactIntl.formatMessage({
              id: intl.creditPolicy('phone-number-registry-title'),
            })}
          />
          <RegisteredPhoneNumber policyInject={useCountryPolicy} />
        </FieldWrapper>
        <FraudIndicatorsCTA />
      </Section>
      <Section
        title={reactIntl.formatMessage({
          id: intl.creditPolicyNew('people_and_company_search'),
        })}
      >
        <FieldWrapper isAdvanced>
          <FieldTitle
            isAdvanced
            title={reactIntl.formatMessage({
              id: intl.creditPolicyNew('people-id-title'),
            })}
            desc={reactIntl.formatMessage({
              id: intl.creditPolicyNew('people-id-desc'),
            })}
          />
          <RejectPeople />
        </FieldWrapper>
        <FieldWrapper isAdvanced>
          <FieldTitle
            isAdvanced
            title={reactIntl.formatMessage({
              id: intl.creditPolicyNew('company-id-title'),
            })}
            desc={reactIntl.formatMessage({
              id: intl.creditPolicyNew('company-id-desc'),
            })}
          />
          <RejectCompany />
        </FieldWrapper>
      </Section>
      <Section
        title={reactIntl.formatMessage({
          id: intl.creditPolicyNew('financial_indicators'),
        })}
      >
        <FieldWrapper>
          <FieldTitle
            title={reactIntl.formatMessage({
              id: intl.creditPolicyNew('equity'),
            })}
            desc={reactIntl.formatMessage({
              id: intl.creditPolicyNew('equity-desc'),
            })}
          />
          <EquityInput />
        </FieldWrapper>
        <FieldWrapper isAdvanced>
          <FieldTitle
            isAdvanced
            title={reactIntl.formatMessage({
              id: intl.creditPolicyNew('company-vat-title'),
            })}
            desc={reactIntl.formatMessage({
              id: intl.creditPolicyNew('company-vat-desc'),
            })}
          />
          <CompanyVAT />
          <Box mt={4}>
            <ProTips
              tip={reactIntl.formatMessage({
                id: intl.creditPolicyNew(COMPANY_VAT),
              })}
            />
          </Box>
        </FieldWrapper>
      </Section>
    </Stack>
  )
}

export default DanishPolicy
