import React from 'react'
import { useFraudIndicators } from '../../FraudIndicatorsProvider'
import DanishFraudIndicators from './DanishFraudIndicators'
import SwedishFraudIndicators from './SwedishFraudIndicators'
import NorwegianFraudIndicators from './NorwegianFraudIndicators'
import FinnishFraudIndicators from './FinnishFraudIndicators'

const FraudIndicatorsContent = () => {
  const { selectedCountry } = useFraudIndicators()

  switch (selectedCountry) {
    case 'dk':
      return <DanishFraudIndicators />
    case 'se':
      return <SwedishFraudIndicators />
    case 'no':
      return <NorwegianFraudIndicators />
    case 'fi':
      return <FinnishFraudIndicators />
  }
}

export default FraudIndicatorsContent
