import { createContext, useContext, useState } from 'react'
import { SupportedCountry } from 'types/general'
import { FraudIndicatorsType } from './types'

const FraudIndicatorsContext = createContext<FraudIndicatorsType>({
  selectedCountry: 'dk',
  setSelectedCountry: () => {},
})

export const useFraudIndicators = () => useContext(FraudIndicatorsContext)

type Props = {
  children: React.ReactNode
}

const FraudIndicatorsProvider = ({ children }: Props) => {
  const [selectedCountry, setSelectedCountry] = useState<SupportedCountry>('dk')
  return (
    <FraudIndicatorsContext.Provider value={{ selectedCountry, setSelectedCountry }}>
      {children}
    </FraudIndicatorsContext.Provider>
  )
}

export default FraudIndicatorsProvider
