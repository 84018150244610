import { InputField } from 'components-new'
import { CountryPolicyContextType } from 'pages/advanced-credit-policy/types'
import React from 'react'

type DistanceToCEOProps = {
  policyInject: () => CountryPolicyContextType
  isAdvanced?: boolean
}
const DistanceToCEO = ({ policyInject, isAdvanced = true }: DistanceToCEOProps) => {
  const { policy, setPolicy, isInferiorPlan, selectedCountry } = policyInject()
  const defVal = policy.distance_to_ceo ?? 0
  const [val, setVal] = React.useState<number>(defVal)

  // this is needed to set the initial value after it's been null
  React.useEffect(() => {
    if (typeof policy.distance_to_ceo === 'number') {
      setVal(policy.distance_to_ceo)
    }
  }, [policy.distance_to_ceo])

  return (
    <InputField
      dataCy={`distance-to-ceo-input-${selectedCountry}`}
      placeholder={'Enter a distance (km)'}
      value={val}
      addOn={'KM'}
      addOnPosition="end"
      handleChange={function (value: string): void {
        if (isNaN(Number(value))) return
        setVal(Number(value))
        setPolicy({ ...policy, distance_to_ceo: Number(value) })
      }}
      disabled={isAdvanced && isInferiorPlan}
    />
  )
}

export default DistanceToCEO
