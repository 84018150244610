const fraudIndicatorsContainer = {
  backgroundColor: 'common.white',
  marginTop: 4,
  marginX: '20px !important',
  padding: 6,
  gap: '96px',
}

export default {
  fraudIndicatorsContainer,
}
