import React from 'react'
import { Modal } from '@mui/material'
import PlanRestrictionModalCanvas from './PlanRestrictionModalCanvas'
import { useCountryPolicy } from 'pages/advanced-credit-policy/CountryPolicyProvider'
import { PLAN_RESTRICTION_FORM_ID } from './hubspotformIdconstants'

const TITLE = 'restriction-modal-title'

const BULLET_ONE = 'restriction-modal-list-1'
const BULLET_TWO = 'restriction-modal-list-2'
const BULLET_THREE = 'restriction-modal-list-3'
const BULLET_POINTS = [BULLET_ONE, BULLET_TWO, BULLET_THREE]

const PROMPT = 'restriction-modal-paragraph'

const PlanRestrictionModal = () => {
  const { isRestrictionModalOpen: isOpen, setIsRestrictionModalOpen: setIsOpen } =
    useCountryPolicy()

  return (
    <Modal open={isOpen} onClose={() => setIsOpen(false)} closeAfterTransition>
      <PlanRestrictionModalCanvas
        formId={PLAN_RESTRICTION_FORM_ID}
        onClose={() => setIsOpen(false)}
        title={TITLE}
        bulletPoints={BULLET_POINTS}
        prompt={PROMPT}
      />
    </Modal>
  )
}

export default PlanRestrictionModal
